import { setSwaggerSpec } from "./localStorageService";

export async function fetchJsonConfig() {
  let response = await fetch(
    "config/config.json",
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  );
  return await response.json();
}

// Function to get Swagger JSON definiton for each API
export async function getSwaggerJsonDefinition(){
  const config = await fetchJsonConfig();

  const fetchAS = fetch(
    config.asSwaggerJsonURL
  ).then((res) => res.json());
  
  const fetchCMS = fetch(
    config.cmsSwaggerJsonURL
  ).then((res) => res.json());
  
  const fetchReporting = fetch(
    config.reportingSwaggerJsonURL
  ).then((res) => res.json());
  
  const allData = Promise.all([fetchAS, fetchCMS, fetchReporting]);
  let data = await allData;

  data.forEach(spec => {
    spec.info.contact.name = config.clientName;
    spec.info.contact.url = config.contactURL;
    spec.info.license.name = `${config.clientName} License`;
    spec.info.license.url = config.licenseURL;
    spec.info["x-logo"].url = config.brandLogoURL;
    spec.info["x-logo"].altText = `${config.clientName} Logo`;
    setSwaggerSpec(spec.info.title, spec);
  });

  return data;
}