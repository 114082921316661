import { useState, useEffect } from "react";
import MainPage from "./components/MainPage";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getSwaggerJsonDefinition, fetchJsonConfig } from "./services/configurationService";

const LoadingComponent = () => {
  return(
    <Box sx={{ display: "flex", justifyContent: "center"}}>
      <CircularProgress />
    </Box>
  )
};

const App: React.FC = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    async function fetchConfig() {
      setConfig(await fetchJsonConfig());
      await getSwaggerJsonDefinition();
    }
    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {config === null ? LoadingComponent() : <MainPage config={config} />}
    </div>
  );
};

export default App;
