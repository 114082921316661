export function setCurrentPage(page: string) {
    localStorage.setItem('currentPage', page);
}

export function getCurrentPage(): string {
    return localStorage.getItem('currentPage') || "introduction";
}

export function setCurrentLang(lang: string) {
    localStorage.setItem('lang', lang);
}

export function getCurrentLang(): string {
    return localStorage.getItem('lang') || "en-US";
}

export function setSwaggerSpec(specKey: string, specObject: any){
    localStorage.setItem(specKey, JSON.stringify(specObject));
}

export function getSwaggerSpec(specKey: string): any {
    const swaggerSpec = localStorage.getItem(specKey) || "";
    return JSON.parse(swaggerSpec);
}